<template>
    <div class="mx-0 px-0 py-0 mt-3">
        <v-row no-gutters class="mx-4">
            <v-col cols="12">
                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="headers"
                    :items="filters"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    @click:row="openProject"
                    :item-class="itemRowBackground"
                    :height="$vuetify.breakpoint.mdAndDown ? '75vh' : '66vh'"
                    fixed-header
                >
                    <template v-slot:top>
                        <div>
                            <v-row
                                no-gutter
                                style="background-color: #eeeeee"
                                :class="
                                    $vuetify.breakpoint.xs
                                        ? 'd-flex justify-center py-4 mx-0'
                                        : 'd-flex justify-start py-4 mx-0'
                                "
                            >
                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[0].color"
                                                    label
                                                    style="height: 50px"
                                                >
                                                    <v-icon center>
                                                        {{ insights[0].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            style="font-size: 20px; font-weight: bold;"
                                                            >{{
                                                                insights[0]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{ filters.length }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>
                                <v-spacer v-if="!$vuetify.breakpoint.xs" />
                                <div class="pt-5 pr-3">
                                    <v-btn
                                        depressed
                                        color="primary"
                                        @click="openProjectForm"
                                        rounded
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                        {{
                                            !$vuetify.breakpoint.xs ? 'New' : ''
                                        }}
                                    </v-btn>
                                </div>
                            </v-row>
                        </div>
                    </template>
                    <!-- Headers -->

                    <template v-slot:[`header.reference`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectReference"
                        />
                    </template>

                    <template v-slot:[`header.sapCode`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectSapCode"
                            class="d-flex mb-n4"
                        />
                    </template>

                    <template v-slot:[`header.client`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectClient"
                        />
                    </template>

                    <template v-slot:[`header.name`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectName"
                        />
                    </template>

                    <template v-slot:[`header.accountManager`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectAManager"
                        />
                    </template>

                    <template v-slot:[`header.projectManager`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectPManager"
                        />
                    </template>

                    <template v-slot:[`header.city`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectCity"
                        />
                    </template>

                    <template v-slot:[`header.collaborators`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectCollaborator"
                        />
                    </template>

                    <template v-slot:[`header.currency`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectCurrency"
                        />
                    </template>

                    <template v-slot:[`header.invoiced`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="filterBilled"
                        />
                    </template>

                    <!-- Items -->

                    <template v-slot:[`item.accountManager`]="{ item }">
                        <div class="my-4">
                            {{ item.accountManagerName }}
                        </div>
                    </template>
                    <template v-slot:[`item.projectManager`]="{ item }">
                        <div class="my-4">
                            {{ item.projectManagerName }}
                        </div>
                    </template>
                    <template v-slot:[`item.client`]="{ item }">
                        <div class="my-4">
                            {{ item.clientName }}
                        </div>
                    </template>
                    <template v-slot:[`item.collaborators`]="{ item }">
                        <div v-if="showTooltip(item)">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-for="(collaborator,
                                        index) of item.dataCollaborators.filter(
                                            c =>
                                                c != item.projectManagerName &&
                                                c != item.accountManagerName
                                        )"
                                        :key="index"
                                        class="my-4"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <p v-if="index == 0">
                                            {{ collaborator }}
                                        </p>
                                        <p
                                            v-else-if="
                                                index == 1 &&
                                                    item.dataCollaborators.filter(
                                                        c =>
                                                            c !=
                                                                item.projectManagerName &&
                                                            c !=
                                                                item.accountManagerName
                                                    ).length < 3
                                            "
                                        >
                                            {{ collaborator }}
                                        </p>
                                        <p v-else-if="index === 2">
                                            +
                                            {{
                                                item.dataCollaborators.filter(
                                                    c =>
                                                        c !=
                                                            item.projectManagerName &&
                                                        c !=
                                                            item.accountManagerName
                                                ).length - 1
                                            }}
                                        </p>
                                    </div>
                                </template>
                                <div>
                                    <template>
                                        <div
                                            v-for="(collaborator,
                                            index) of item.dataCollaborators.filter(
                                                c =>
                                                    c !=
                                                        item.projectManagerName &&
                                                    c != item.accountManagerName
                                            )"
                                            :key="index"
                                            class="my-4"
                                        >
                                            <p>
                                                {{ collaborator }}
                                            </p>
                                        </div>
                                    </template>
                                </div>
                            </v-tooltip>
                        </div>
                        <div
                            v-for="(collaborator,
                            index) of item.dataCollaborators.filter(
                                c =>
                                    c != item.projectManagerName &&
                                    c != item.accountManagerName
                            )"
                            :key="index"
                            class="my-4"
                            v-else
                        >
                            {{ collaborator }}
                        </div>
                    </template>
                    <template v-slot:[`item.currency`]="{ item }">
                        <div class="my-4">
                            <div class="my-4">
                                {{ item.currency }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        <div class="my-4" style="text-align: end">
                            <div class="my-4">
                                {{ formatCurrency(item) }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:[`item.invoiced`]="{ item }">
                        <div
                            class="my-4 font-weight-bold"
                            @click.stop="openInvoiceDetails(item)"
                            :style="{
                                cursor: item.totalInvoiced
                                    ? 'pointer'
                                    : 'default',

                                textAlign: 'end',
                            }"
                        >
                            {{
                                item.totalInvoiced
                                    ? formatInvoiced(item)
                                    : ' 0 (0%)'
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.b2b`]="{ item }">
                        <div class="my-4" style="text-align: end">
                            {{ formatB2B(item.b2b) }}
                        </div>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <div class="d-flex justify-center my-4">
                            <v-btn
                                icon
                                small
                                @click.stop="editProject(item)"
                                :disabled="loading"
                            >
                                <v-icon small :color="iconColor(item)"
                                    >mdi-pencil</v-icon
                                >
                            </v-btn>
                            <v-btn
                                icon
                                small
                                @click.stop="openProjectClosingProcess(item)"
                                v-if="isProjectManager(item)"
                            >
                                <v-icon small :color="iconColor(item)"
                                    >mdi-archive-lock-open-outline</v-icon
                                >
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!-- project form -->
        <v-dialog
            :retain-focus="false"
            v-model="projectDialog"
            persistent
            max-width="640px"
        >
            <ProjectForm
                v-if="projectDialog"
                :quotes="awardedQuotes"
                :users="users"
                :clients="clients"
                :project="selectedProject"
                :originalProject="originalProject"
                :createForm="createProject"
                :selectedQuote="selectedQuote"
                @closeDialog="closeDialog"
                @addProject="addProject"
                @replaceProject="replaceProject"
                @removeProject="removeProject"
            />
        </v-dialog>
        <!--QUOTE-->
        <v-dialog
            :retain-focus="false"
            v-model="dialogQuote"
            fullscreen
            ref="dialogQuote"
        >
            <v-card class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="backToProjects">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="`height:${this.heightPopUp}px`"
                >
                    <router-view />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- project  -->
        <v-dialog
            :retain-focus="false"
            v-model="viewProject"
            fullscreen
            ref="dialogQuotesBySection"
        >
            <v-card class="fill-height" v-if="viewProject">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="backToProjects">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ selectedProject.name }}
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="`height:${this.heightPopUp}px`"
                >
                    <router-view />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- invoice Details  -->
        <v-dialog :retain-focus="false" v-model="invoiceDetails" persistent>
            <ProjectInvoiceDetails
                v-if="invoiceDetails"
                :project="selectedProject"
                @close="closeInvoiceDetails"
            />
        </v-dialog>
        <!-- CLOSING PROJECT PROCESS -->
        <v-dialog
            v-model="closingProcessDialog"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <ProjectClosingProcess
                v-if="closingProcessDialog"
                :project="selectedProject"
                @close="closeProjectClosingProcess"
                @remove="removeProject"
            />
        </v-dialog>
    </div>
</template>

<script>
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations, mapGetters } from 'vuex'

export default {
    name: 'GenericProjects',
    components: {
        ProjectForm: () => import('@/components/Projects/ProjectForm.vue'),
        ProjectInvoiceDetails: () =>
            import('@/components/Projects/ProjectInvoiceDetails.vue'),
        ProjectClosingProcess: () =>
            import('@/components/Projects/ProjectClosingProcess.vue'),
    },
    data: () => ({
        filterBilled: null,
        headers: [
            {
                text: 'Reference',
                value: 'reference',
                align: 'center',
                sortable: false,
                width: 80,
            },
            {
                text: 'SAP Code',
                value: 'sapCode',
                align: 'center',
                width: 80,
            },
            {
                text: 'Name',
                value: 'name',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Client',
                value: 'client',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Account M.',
                value: 'accountManager',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Project M.',
                value: 'projectManager',
                align: 'left',
                sortable: false,
            },
            {
                text: 'City',
                value: 'city',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Collaborators',
                value: 'collaborators',
                align: 'left',
                sortable: false,
            },
            {
                text: 'Currency',
                value: 'currency',
                align: 'center',
                sortable: false,
                width: 80,
            },
            {
                text: 'Price',
                value: 'price',
                align: 'right',
            },
            {
                text: 'Billed',
                value: 'invoiced',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Balance to Bill',
                value: 'b2b',
                sortable: true,
                align: 'center',
            },
            {
                text: 'Action',
                value: 'action',
                align: 'center',
                sortable: false,
            },
        ],
        height: 0,
        projects: [],
        loading: false,
        users: [],
        clients: [],
        projectDialog: false,
        createProject: false,
        selectedProject: {},
        heightPopUp: 0,
        viewProject: false,
        dialogQuote: false,
        costs: undefined,
        settings: undefined,
        awardedQuotes: [],
        projectClient: undefined,
        projectName: undefined,
        projectPManager: undefined,
        projectAManager: undefined,
        projectReference: undefined,
        projectSapCode: undefined,
        projectCity: undefined,
        projectCollaborator: undefined,
        projectQuote: undefined,
        selectedQuote: undefined,
        projectCurrency: undefined,
        openTaskForm: false,
        originalProject: {},
        invoiceDetails: false,
        insights: [
            {
                color: '#3498DB',
                icon: 'mdi-clipboard-multiple',
                iconColor: '#1A5276',
                title: 'PROJECTS',
            },
        ],
        authUser: undefined,
        closingProcessDialog: false,
    }),
    async mounted() {
        try {
            this.loading = true
            // retrive project
            this.projects = await API.getGenericProjects({})
            // set auth user
            const {
                data: { user },
            } = await API.getMyInfo()
            this.authUser = user
            // set users
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            // set clients
            const {
                data: { clients },
            } = await API.getLiteClients()
            this.clients = clients
            // set settings
            const {
                data: { settings },
            } = await API.getSettings()
            let orderSettings = settings.filter(x => x.name == 'Company')
            orderSettings[1] = settings.find(x => x.name == 'Exchange')
            orderSettings[2] = settings.find(x => x.name == 'Permissions')
            orderSettings[3] = settings.find(x => x.name == 'Quotes')
            this.settings = orderSettings
            // complete project data
            this.completeProjectData()
            // open new project creation form if a won quotation is sent from another route
            const quoteId = this.$router.currentRoute.params.quoteId
            if (quoteId) {
                try {
                    this.awardedQuotes = await API.getProjectQuotes()
                    this.selectedQuote = this.awardedQuotes.find(
                        quote => quote.id === quoteId
                    )
                } catch (error) {
                    console.log(error.message)
                }
                this.openProjectForm()
            }
            // calculate project b2b
            this.projects.forEach(project => {
                project.b2b = project.price - (project.totalInvoiced || 0)
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
            if (this.getProjectId) {
                const project = this.projects.find(
                    p => p.id == this.getProjectId
                )
                if (project) {
                    this.openProject(project)
                }
            }
            this.setProjectId(undefined)
        }
    },
    updated() {
        this.onResize()
    },
    provide() {
        return {
            openQuoteFromProjects: this.openQuoteFromProjects,
        }
    },
    computed: {
        ...mapGetters(['getProjectId']),
        filters() {
            let conditions = []

            if (this.projectClient) {
                conditions.push(this.filterClient)
            }

            if (this.projectName) {
                conditions.push(this.filterName)
            }

            if (this.projectAManager) {
                conditions.push(this.filterAManager)
            }

            if (this.projectPManager) {
                conditions.push(this.filterPManager)
            }

            if (this.projectReference) {
                conditions.push(this.filterReference)
            }

            if (this.projectSapCode) {
                conditions.push(this.filterSapCode)
            }

            if (this.projectCity) {
                conditions.push(this.filterCity)
            }

            if (this.projectCollaborator) {
                conditions.push(this.filterCollaborators)
            }

            if (this.projectCurrency) {
                conditions.push(this.filterCurrency)
            }

            if (this.filterBilled) {
                conditions.push(this.filterByBilled)
            }

            if (conditions.length > 0) {
                const result = this.projects.filter(project => {
                    return conditions.every(condition => {
                        return condition(project)
                    })
                })
                return result
            }
            this.sortByReference()
            return this.projects
        },
        totals() {
            let totals = {
                totalUsd: 0,
                totalCop: 0,
                qtyUsd: 0,
                qtyCop: 0,
                b2bUSD: 0,
                b2bCOP: 0,
            }

            this.filters.forEach(project => {
                if (project.price != null) {
                    if (project.currency == 'COP') {
                        totals.totalCop += parseFloat(project.price)
                        totals.b2bCOP +=
                            project.price - (project.totalInvoiced || 0)
                        totals.qtyCop += 1
                    } else {
                        totals.totalUsd += parseFloat(project.price)
                        totals.b2bUSD +=
                            project.price - (project.totalInvoiced || 0)
                        totals.qtyUsd += 1
                    }
                }
            })

            return totals
        },
    },
    methods: {
        ...mapMutations(['setErrorItems', 'setProjectId']),
        // returns the color that an icon should take when a project is 100% invoiced
        iconColor(item) {
            return this.formatInvoiced(item).includes('100%')
                ? 'white'
                : 'black'
        },
        // paints the background of a row for a one hundred percent billable project
        itemRowBackground(item) {
            return this.formatInvoiced(item).includes('100%')
                ? 'item_class'
                : ''
        },
        // filter by billed column
        filterByBilled(item) {
            return this.formatInvoiced(item).includes(this.filterBilled)
        },
        // close the dialogue to start with the process of closing a project.
        closeProjectClosingProcess() {
            this.selectedProject = {}
            this.closingProcessDialog = false
        },
        // opens the dialogue to start with the process of closing a project.
        openProjectClosingProcess(project) {
            this.selectedProject = _.cloneDeep(project)
            this.closingProcessDialog = true
        },
        // validate if the authenticated user is the project manager of a project
        isProjectManager(project) {
            return !project ? false : project.projectManager == this.authUser.id
        },
        sortByReference() {
            this.projects.sort((a, b) => {
                return b.reference - a.reference
            })
        },
        formatNumber(value) {
            return new Intl.NumberFormat('de-DE', {
                style: 'decimal',
                maximumFractionDigits: 0,
            }).format(value)
        },
        filterCurrency(item) {
            return (
                item.currency &&
                item.currency
                    .toLowerCase()
                    .includes(this.projectCurrency.toLowerCase())
            )
        },
        filterCollaborators(item) {
            let res = false
            for (let i = 0; i < item.dataCollaborators.length; i++) {
                let collaborator = item.dataCollaborators[i]
                if (collaborator) {
                    res = collaborator
                        .toLowerCase()
                        .includes(this.projectCollaborator.toLowerCase())
                }
                if (res) break
            }
            return res
        },
        filterCity(item) {
            return (
                item.city &&
                item.city.toLowerCase().includes(this.projectCity.toLowerCase())
            )
        },
        filterReference(item) {
            return (
                item.reference &&
                item.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectReference.toLowerCase())
            )
        },
        filterSapCode(item) {
            return (
                item.sapCode &&
                item.sapCode
                    .toString()
                    .toLowerCase()
                    .includes(this.projectSapCode.toLowerCase())
            )
        },
        filterPManager(item) {
            return (
                item.projectManagerName &&
                item.projectManagerName
                    .toLowerCase()
                    .includes(this.projectPManager.toLowerCase())
            )
        },
        filterAManager(item) {
            return (
                item.accountManagerName &&
                item.accountManagerName
                    .toLowerCase()
                    .includes(this.projectAManager.toLowerCase())
            )
        },
        filterName(item) {
            if (item.name) {
                return (
                    item.name &&
                    item.name
                        .toLowerCase()
                        .includes(this.projectName.toLowerCase())
                )
            } else {
                return false
            }
        },
        filterClient(item) {
            return (
                item.clientName &&
                item.clientName
                    .toLowerCase()
                    .includes(this.projectClient.toLowerCase())
            )
        },
        completeProjectData() {
            for (let project of this.projects) {
                project.clientName = this.getClientName(project.client)
                project.accountManagerName = this.getUserName(
                    project.accountManager
                )
                project.projectManagerName = this.getUserName(
                    project.projectManager
                )
                project.dataCollaborators = project.collaborators.map(
                    collaborator => {
                        return this.getUserName(collaborator)
                    }
                )
                project.dataQuotes = project.quotes.map(quote => {
                    return this.getQuoteName(quote)
                })
            }
        },
        async openQuoteFromProjects(quoteId, costs) {
            this.backToProjects()
            const quote = this.awardedQuotes.find(quote => quote.id === quoteId)
            await this.openQuote(quote, costs)
        },
        async openQuote(quote, costs) {
            this.dialogQuote = true
            this.costs = costs
            this.$router.replace({
                name:
                    quote.status !== 'SENT'
                        ? 'quoteGIProject'
                        : 'quoteFollowUpProject',
                params: {
                    id: quote.id,
                    quote,
                    settings: this.settings,
                },
            })
        },
        backToProjects() {
            this.dialogQuote = false
            this.viewProject = false
            this.$router
                .push({
                    name: 'projects-generic',
                })
                .catch(error => error && null)
        },
        async openProject(project) {
            if (!this.loading) {
                this.viewProject = true
                this.selectedProject = _.cloneDeep(project)
                // const quotes = await API.getProjectQuotes(project.id)
                this.$router.push({
                    name: 'project-generic',
                    params: {
                        id: project.id,
                        project,
                        clients: this.clients,
                        users: this.users,
                    },
                })
            }
        },
        editProject(project) {
            this.selectedProject = _.cloneDeep(project)
            this.originalProject = _.cloneDeep(project)
            this.createProject = false
            this.projectDialog = true
        },
        getQuoteName(id) {
            const quoteId = id.split('/')[0]
            if (this.awardedQuotes.length > 0) {
                return this.awardedQuotes.find(quote => quote.id == quoteId)
                    ?.name
            } else {
                return ''
            }
        },
        getUserName(id) {
            if (this.users.length > 0) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                return this.clients.find(client => client.id == id)?.name
            } else {
                return ''
            }
        },
        onResize() {
            this.height = window.innerHeight - 112
            this.heightPopUp = window.innerHeight - 62
        },
        openProjectForm() {
            let project = {
                quotes: [],
                reference: '',
                name: '',
                currency: '',
                generic: true,
            }
            this.selectedProject = project
            this.projectDialog = true
            this.createProject = true
        },
        closeDialog() {
            this.selectedQuote = undefined
            this.originalProject = undefined
            this.projectDialog = false
        },
        addProject(project) {
            this.projects.push(project)
            this.completeProjectData()
        },
        replaceProject(id, changes) {
            const index = this.projects.findIndex(p => p.id === id)
            if (index >= 0) {
                this.projects.splice(
                    index,
                    1,
                    Object.assign(this.projects[index], changes)
                )
            }
            this.completeProjectData()
        },
        removeProject(id) {
            this.projects = this.projects.filter(p => p.id != id)
        },
        formatCurrency(item) {
            return new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0,
            }).format(item.price)
        },
        formatInvoiced(item) {
            const totalAmount = item.totalInvoiced
            const percentage = (totalAmount / item.price) * 100
            const formattedInvoiced = new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0,
            }).format(totalAmount)
            return `${formattedInvoiced} (${percentage.toFixed(0)}%)`
        },
        formatB2B(item) {
            item = Math.floor(item)
            const formattedB2B = new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0,
            }).format(item)
            return formattedB2B
        },
        openInvoiceDetails(item) {
            if (item.totalInvoiced && item.totalInvoiced > 0) {
                this.invoiceDetails = true
                this.selectedProject = _.cloneDeep(item)
            }
        },
        closeInvoiceDetails() {
            this.invoiceDetails = false
            this.selectedProject = _.cloneDeep({})
        },
        showTooltip(item) {
            if (item.dataCollaborators) {
                return (
                    item.dataCollaborators.filter(
                        c =>
                            c != item.projectManagerName &&
                            c != item.accountManagerName
                    ).length > 2
                )
            }
            return false
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead th {
    background-color: #eeeeee !important;
}
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.item_class {
    background-color: #3385d7 !important;
    color: white !important;
}
</style>
